import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const svgVariants = {
  hidden: { rotate: 0 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export default function WaveCurveLine() {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();
  const svgRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    const observer = new IntersectionObserver(
      (entries) => {
        if (!isMounted) return;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            controls.start("visible");
          } else {
            setIsInView(false);
            controls?.start("hidden");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    return () => {
      isMounted = false;
      if (svgRef.current) {
        observer.unobserve(svgRef.current);
      }
    };
  }, [controls]);

  return (
    <motion.svg
      ref={svgRef}
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      width="207"
      height="14"
      viewBox="0 0 207 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="w-full"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate={controls}
        d="M1.07422 11.9291L27.9934 4.20194C39.6174 0.865514 51.9956 1.2298 63.4015 5.24551L64.0999 5.49087C75.9452 9.66087 88.8542 9.7348 100.747 5.70087L103.93 4.62087C114.487 1.03909 125.978 1.33159 136.339 5.44373C146.87 9.62551 158.565 9.8548 169.254 6.09194L172.383 4.99051C183.23 1.17194 195.09 1.36373 205.808 5.52944"
        stroke="#FF8E5E"
        strokeWidth="2"
      />
    </motion.svg>
  );
}
