import React from "react";
import { motion } from "framer-motion";

const svgVariants = {
  hidden: { rotate: -10 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};
export default function HalfDownArrow() {
  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      whileInView="visible"
      width="90"
      height="9"
      viewBox="0 0 90 9"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="w-full"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        whileInView="visible"
        d="M0.566406 6.9365C12.1155 4.4399 81.9934 0.717505 88.3663 1.2098C94.7387 1.7021 68.2615 4.7558 52.67 8.0633"
        stroke="#FF8E5E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
}
